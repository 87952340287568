* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
  
html{
  min-height: 100%;
  overflow-x: hidden;
}

//fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
$roboto: 'Roboto', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');
$heebo: 'Heebo', sans-serif;

//color
$white: #fff;
$darker-pink: #E10B7D;
$orange: #EC932B;
$dark-blue: #005080;
$scanner-green: #edfff9;

//accordion colors
$yellow-bg: #FFC96B;
$yellow-bg-light: #ffedcd;
$yellow-txt: #684316;
$pink-bg: #FB40C6;
$pink-bg-light: #ffdcf5;
$pink-txt: #580827;
$lavender-bg: #E4B0FD;
$lavender-bg-light: #f2dcff;
$lavender-txt: #461A8D;
$green-bg: #89f7d1;
$green-bg-light: #d1f5e9;
$green-txt: #13550E;
$purple-bg: #E73DF5;
$purple-bg-light: #fcd5ff;
$purple-txt: #631F6D;

//breakpoint variables
$phone: 640px;
$tablet: 768px;
$desktop: 1024px;

//scrollbar
::-webkit-scrollbar {width: 5px;}
::-webkit-scrollbar-track {background: $white;}
::-webkit-scrollbar-thumb {background: $pink-txt;}

body{font-family: $roboto; font-size: 1.2vw; overflow-x: hidden; background-color: $dark-blue;}
a{text-decoration: none; display: block;}
img{pointer-events: none;}
p{padding: 0% 0% 2% 0%; line-height: 125%;}
p>svg{width: 1.5rem; margin-right: 2%;}
ul{list-style-type: square;}
li>ul{list-style-type: circle; margin-top: 2%;}
.container{width: 100%; position: relative; word-break: break-word; overflow: hidden;}
.row{width:100%; display: flex; flex-wrap: wrap; margin: 0 auto;}
.background-elements{position: absolute;}
.no-list-style{list-style: none;}
.hex-container{
  position: absolute; top: 0; z-index: -1;

  .cursor {
    width:100px; height:100px; border-radius:100%; position: absolute; z-index: -1; opacity: 0; background: linear-gradient(45deg, $orange, $yellow-bg, $green-bg, $purple-bg, $pink-bg); background-size: 400%; animation: glower 20s linear infinite; filter: blur(10px);
    
    @keyframes glower {
      0% {background-position: 0 0;}
      50% {background-position: 400% 0;}
      100% {background-position: 0 0;}
    }
  }

  .row {
    display:block; width: 100vw; height: 125px; overflow: hidden; display: flex; align-items: center; justify-content: center; flex-wrap: wrap; margin-top: -33px; margin-left: -33px;
    .hexagon {
      position: relative; width: 100px;  height: 57.74px; background-color: $dark-blue; margin: 32.87px 3px; display: block; transition: all .2s linear;
      &::after{content: ""; position: absolute; width: 0; border-left: 50px solid transparent; border-right: 50px solid transparent; top: 100%; width: 0; border-top: 28.87px solid $dark-blue; transition: all .2s linear;}
      &::before {content: ""; position: absolute; width: 0; border-left: 50px solid transparent; border-right: 50px solid transparent; bottom: 100%; border-bottom: 28.87px solid $dark-blue; transition: all .2s linear;}
    }
    &.row-moved {margin-left: 19px;}
  }
}

//fade in
.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

//banner
.banner-mobile{display: none;}
.banner{
  background: linear-gradient(180deg, #0b1c2a 0%, transparent 100%); height: 85vh; position: relative;
  img{width: 100%;}
  .banner-boxes{top: -41vh; opacity: 0.5;}
  .banner-swirls{top: -34vh; opacity: 0.3;}
  .banner-figure{width: 40%; bottom: 10vh; right: 23vw;}
  .banner-phone{width: 19%; top: 14vh; left: 17vw;}
  .banner-thumbprint{width: 15%; top: 30vh; right: 10vw;}
  .banner-icon-document{width: 3%; top: 20vh; left: 5vw; filter: drop-shadow(0px 0px 4px #fff);}
  .banner-icon-graph{width: 3%; bottom: 51vh; left: 39vw; filter: drop-shadow(0px 0px 4px #fff);}
  .banner-icon-padlock{width: 3%; top: 19vh; right: 26vw; filter: drop-shadow(0px 0px 4px #fff);}
  .banner-icon-person{width: 3%; bottom: -6vh; right: 5vw; filter: drop-shadow(0px 0px 4px #fff);}
  .banner-icon-shield-one{width: 3%; top: 12vh; right: 2vw; filter: drop-shadow(0px 0px 4px #fff);}
  .banner-icon-shield-two{width: 3%; bottom: 3vh; left: 6vw; filter: drop-shadow(0px 0px 4px #fff);}
  .banner-panel-one{
    img{width: 21%; position: absolute; top: 18vh; left: 20vw; transform: rotate(-9deg); pointer-events: all; transition: 0.2s ease; animation: shake-one 5s infinite; box-shadow: -5px 5px 15px 0px #fff; border-radius: 0.5rem;}
    .panel-one-answer{width: 21%; position: absolute; top: 28vh; left: 20vw; background-color: #6d96fb; color: #fff; padding: 2%; z-index: 1; border: 0.5px solid #ffffff73; border-radius: 0 0 0.5rem 0.5rem; display: none;}
    &:hover{
      img{transform: rotate(0deg); animation: none;}
      .panel-one-answer{display: block;}
    }
  }
  .banner-panel-two{
    img{width: 21%; position: absolute; top: 39vh; left: 10vw; transform: rotate(-10deg); pointer-events: all; transition: 0.2s ease; animation: shake-two 5.4s infinite; box-shadow: -5px 5px 15px 0px #fff; border-radius: 0.5rem;}
    .panel-two-answer{width: 21%; position: absolute; top: 49.5vh; left: 10vw; background-color: #f1b658; color: #fff; padding: 2%; z-index: 1; border: 0.5px solid #ffffff73; border-radius: 0 0 0.5rem 0.5rem; display: none;}
    &:hover{
      img{transform: rotate(0deg); animation: none;}
      .panel-two-answer{display: block;}
    }
  }
  .banner-panel-three{
    img{width: 21%; position: absolute; top: 51vh; left: 23.5vw; transform: rotate(-11deg); pointer-events: all; transition: 0.2s ease; animation: shake-three 5.9s infinite; box-shadow: -5px 5px 15px 0px #fff; border-radius: 0.5rem;}
    .panel-three-answer{width: 21%; position: absolute; top: 61.75vh; left: 23.5vw; background-color: #f552ce; color: #fff; padding: 2%; z-index: 1; border: 0.5px solid #ffffff73; border-radius: 0 0 0.5rem 0.5rem; display: none;}
    &:hover{
      img{transform: rotate(0deg); animation: none;}
      .panel-three-answer{display: block;}
    }
  }
  .title-text{
    color: $white; font-weight: 900; bottom: -10vh; text-transform: uppercase; width: 100%; line-height: 82%; text-align: center;
    img{width: 80%; filter: drop-shadow(0.2vw 0.2vw 0.3vw #0000007d);}
  }
  .title-act{font-size: 2.25vw; font-weight: 300; line-height: 140%;}
}
.top-logos{
  display: flex; width: 100%; align-items: center; padding: 0.5% 2%; z-index: 2; position: relative;
  .en-logo{ width: 50%;
    img{width: 25%;}
  }
  .fnf-logo{ width: 50%; text-align: right;
    img{width: 28%;}
  }
}
.line{position: absolute; width: 7%; height: 19vh; top: 36vh; right: 12.1vw; border-radius: 50%; overflow: hidden;}
.line .scanner {position: absolute; top: 0; left: 0; background: $scanner-green; width: 100%; height: 1px; box-shadow: 0 0 10px 5px $scanner-green; opacity: 0.925; animation: scanner-loop 5s ease-in-out infinite;}

@keyframes scanner-loop{
  0%{top: 0;}
  50%{top: 100%;}
  100%{top: 0;}
}
@keyframes shake-one {
  0%{transform: rotate(-9deg);}
  5%{transform: rotate(-9deg) translate(3vh, 0);}
  10%{transform: rotate(-9deg);}
  15%{transform: rotate(-9deg) translate(1vh, 0);}
  20%{transform: rotate(-9deg);}
  100%{transform: rotate(-9deg);}
}
@keyframes shake-two {
  0%{transform: rotate(-10deg);}
  5%{transform: rotate(-10deg) translate(-3vh, 0);}
  10%{transform: rotate(-10deg);}
  15%{transform: rotate(-10deg) translate(-1vh, 0);}
  20%{transform: rotate(-10deg);}
  100%{transform: rotate(-10deg);}
}
@keyframes shake-three {
  0%{transform: rotate(-11deg);}
  5%{transform: rotate(-11deg) translate(3vh, 0);}
  10%{transform: rotate(-11deg);}
  15%{transform: rotate(-11deg) translate(1vh, 0);}
  20%{transform: rotate(-11deg);}
  100%{transform: rotate(-11deg);}
}

.separation-line{border: 1px solid $white; width: 64%; margin: 10% auto 5% auto;}
.questions-container{display: none;}

//discussions
.discussions-container{position: relative;}
.discussions-title{color: $white; font-size: 4.5vw; font-family: $roboto; text-transform: uppercase; text-align: center; position: relative; font-weight: 900; margin: 0 auto; line-height: 90%; width: 50%; filter: drop-shadow(0.2vw 0.2vw 0.3vw #0000007d);}
.discussions-title-desktop{display: block;}
.discussions-title-mobile{display: none;}
.discussions-strap{color: $white; font-size: 1.22vw; font-family: $roboto; text-align: center; font-weight: 200; margin: 1.5% auto 3% auto;}
.panel-one{position: relative; display: flex; flex-direction: column; align-items: center; justify-content: center; margin-top: 4%; 
  .panel-members{img{width: 33%; height: 35%;}}
}
.panel-two{position: relative; display: flex; flex-direction: column; align-items: center; justify-content: center; margin-top: 17%;
  .panel-members{img{width: 33%; height: 35%;}}
}
.panel-members{
  display: flex; justify-content: center; width: 35%; transition: 0.3s ease-in-out; z-index: 1; position: relative; margin-bottom: 1%;
  .panel-one-name-one{
    color: $white; font-weight: 800; font-size: 1.75vw; top: 91%; left: 4%; transition: 0.3s ease; text-transform: uppercase; letter-spacing: 2px;
    p{font-size: 1vw; font-weight: 500;}
  }
  .panel-one-name-two{
    color: $white; font-weight: 800; font-size: 1.75vw; top: 110%; left: 36%; transition: 0.3s ease; text-transform: uppercase; letter-spacing: 2px;
    p{font-size: 1vw; font-weight: 500;}
  }
  .panel-one-name-three{
    color: $white; font-weight: 800; font-size: 1.75vw; top: 82%; left: 70%; transition: 0.3s ease; text-transform: uppercase; letter-spacing: 2px;
    p{font-size: 1vw; font-weight: 500;}
  }
  .panel-two-name-one{
    color: $dark-blue; font-weight: 800; font-size: 1.75vw; top: 73%; left: 70%; transition: 0.3s ease; text-transform: uppercase; letter-spacing: 2px;
    p{font-size: 1vw; font-weight: 500;}
  }
  .panel-two-name-two{
    color: $dark-blue; font-weight: 800; font-size: 1.75vw; top: 96%; left: 36%; transition: 0.3s ease; text-transform: uppercase; letter-spacing: 2px;
    p{font-size: 1vw; font-weight: 500;}
  }
  .panel-two-name-three{
    color: $dark-blue; font-weight: 800; font-size: 1.75vw; top: 87%; left: 6%; transition: 0.3s ease; text-transform: uppercase; letter-spacing: 2px;
    p{font-size: 1vw; font-weight: 500;}
  }
  .panel-one-img-one{transform: translate(0, 0%); transition: 0.5s ease;}
  .panel-one-img-two{transform: translate(0, 18%); transition: 0.5s ease;}
  .panel-one-img-three{transform: translate(0, -3%); transition: 0.5s ease;}
  .panel-two-img-one{transform: translate(0, 2%); transition: 0.5s ease;}
  .panel-two-img-two{transform: translate(0, 7%); transition: 0.5s ease;}
  .panel-two-img-three{transform: translate(0, 6%); margin-left: -0.2%; transition: 0.5s ease;}
}
.panel-info{width: 85%;}
.panel-title{color: $white; font-family: $heebo; font-weight: 800; font-size: 3vw; width: 80%; line-height: 100%; text-align: center; filter: drop-shadow(0.2vw 0.2vw 0.3vw #0000007d); position: relative; z-index: 2; text-transform: uppercase;}
.panel-desc{
  color: $white; font-weight: 400; font-size: 1.3vw; line-height: 145%;
  span{font-size: 1vw;}
}
.panel-bg{z-index: -1; height: 140% !important; width: 140% !important; bottom: -37%; right: -20%; transition: 1s cubic-bezier(0, 0.97, 0.8, 1);}
.panel-img-div{width: 0.5%; background: linear-gradient(0deg, transparent 0%, $white 20%, $white 70%, transparent 85%);}
.hover-splash{transform: scale(0); opacity: 0.8;}
.panel-one-bg{transform: translate(2%, 2%) scale(1.05) rotate(-8deg);}
.panel-two-bg{transform: translate(2%, 0%);}

.hacker{width: 11%; left: 3vw; top: 0vh; animation: hacker 4s infinite;}
.hacker-tbl{width: 11%; left: 2vw; top: -5vh;}
.discussions-computer{width: 10%; right: 7vw; bottom: 85vh;}
.discussions-note{width: 5%; right: 10vw; bottom: 91vh; animation: sidetoside 4s infinite;}
.discussions-squares{width: 10%; right: 5.5vw; bottom: 93vh; animation: upanddown 4s infinite;}

@keyframes upanddown {
  0%{transform: translate(0%, 0%);}
  50%{transform: translate(0%, -24%);}
  100%{transform: translate(0%, 0%);}
}

@keyframes sidetoside {
  0%{transform: translate(0%, 0%);}
  50%{transform: translate(22%, -8%);}
  100%{transform: translate(0%, 0%);}
}

@keyframes hacker {
  0%{transform: translate(0%, 0%);}
  50%{transform: translate(5%, -2%);}
  100%{transform: translate(0%, 0%);}
}

.play-btn {
  position: absolute; font-family: $heebo;	border-radius: 6rem; color: $white;	background-color: $orange; cursor: pointer;	overflow: hidden;	display: flex; justify-content: center;	align-items: center; width: 40%; height: 4vw; font-size: 1.75vw; text-transform: uppercase; font-weight: 900; letter-spacing: 4px; z-index: 1;
	.text, .icon-container {position: relative; z-index: 2;}
	.icon-container {
    position: relative; width: 5%; height: 50%; margin-left: 15px; transition: transform 500ms ease;
		.icon {
      position: absolute; left: 0; top: 0; width: 75%; transition: transform 500ms ease, opacity 250ms ease;
			&--left {transform: translateX(-200%); opacity: 0;}
			svg {width: 100%; height: 100%; fill: #fff;}
		}
	}
	&::after {content: ""; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: var(--btn-bg); border-radius: var(--height); z-index: 1; transition: transform 500ms ease;}
	&:hover {
		&::after {transform: translateX(60%);}
		.icon-container{
			transform: translateX(120%);
			.icon {
				&--left {transform: translateX(0); opacity: 1;}
				&--right {transform: translateX(200%); opacity: 0;}
			}
		}
	}
}
.play-btn-panel-one{bottom: -35%;}
.play-btn-panel-two{bottom: -23%;}

//highlights
.highlights-desktop{display: block;}
.highlights-mobile{display: none;}
.highlights-container{width: 100%; margin-top: 14%;}
.highlights-title-section{padding: 0 18%;}
.highlights-title{color: $white; font-size: 3.5vw; font-family: $roboto; text-transform: uppercase; text-align: left; position: relative; font-weight: 900; margin: 0 auto; line-height: 90%; width: 35%; filter: drop-shadow(0.2vw 0.2vw 0.3vw #0000007d);}
.highlights-strap{color: $white; font-size: 1.2vw; font-family: $roboto; text-align: left; font-weight: 200; margin: 1.5% auto 5% auto; text-align: center;}
.highlights-row{display: flex; width: 100%; margin: 0% 0% 3% 0%; padding: 0 12%;}
.highlight{
  position: relative; margin: 0 5%; color: $white; text-align: center; transition: 0.3s ease-in-out; cursor: pointer; display: flex; flex-direction: column; align-items: center;
  svg{position: absolute; top: 55%; right: 32%; width: 35%; fill: #fff; pointer-events: none; transition: 0.2s ease-in-out;}
  .jayantha-svg{top: 58.5%}
  .arittha-shenuka-svg{top: 52%;}
  .gehan-svg{top: 58%;}
  .sujit-sanduni-svg{top: 52%;}
}
.highlight-img{width: 100%;}
.highlight-member-name{font-size: 1.1vw; text-transform: capitalize; font-weight: 400; padding-top: 22%; margin-bottom: 3%; transition: 0.2s ease-in-out;}
.highlight-title{font-weight: 600; font-size: 1.4vw;}
.highlight-img-container{
  border-radius: 3rem; position: relative; overflow: hidden;
  img{top: 0; right: 0; transition: 0.6s ease;}
  .highlight-swirl-img{position: relative; transform: scale(1.05);}
}
.highlight-guest-img{transform: scale(1.05) translate(0, 1%);}
.highlight-bg-clr-pink-faded{background-color: #e4b0fd;}
.highlight-bg-clr-pink{background-color: #fb40c6;}
.highlight-bg-clr-purple{background-color: #e73df5;}
.highlight-bg-clr-blue{background-color: #b0f8df;}
.highlight-bg-clr-yellow{background-color: #ffc96b;}

//popup
.popup-content{width: 35% !important; padding: 0 !important; background: none !important; border: none !important; animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;}
.popup-overlay{background: rgb(0 0 0 / 76%) !important;}
.close{position: absolute; top: -4%; right: -4%; font-size: 2vw; color: #fff; border: 1px solid #fff; border-radius: 3rem; background-color: $orange; width: 7%; text-align: center; cursor: pointer;}
@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

//downloads
.downloads-section{position: relative;}
.downloads-white-bg{background-color: #fffffff0; top: -10vh; width: 100%; height: 100%;}
.downloads-title{color: $white; font-size: 4.5vw; font-family: $roboto; text-transform: uppercase; text-align: center; position: relative; font-weight: 900; margin: 0 auto; line-height: 90%; margin-top: 8%; width: 80%; filter: drop-shadow(0.2vw 0.2vw 0.3vw #0000007d);}
.downloads-strap{color: $white; font-size: 1.2vw; font-family: $roboto; text-align: center; font-weight: 300; margin: 1.5% auto 0 auto; position: relative;}
.downloads-booklet-description{color: $white; width: 75%; text-align: right; font-size: 1vw;}
.downloads-whitepaper-description{color: $white; width: 75%; text-align: left; font-size: 1vw; margin-left: 10%;}
.downloads-bg{
  opacity: 0.07; width: 100%; top: -8vh;
  img{top: 0; width: 100%; height: 65vh; right: 0; left: 0;}
}
.downloads-desktop-bg{display: block;}
.downloads-mobile-bg{display: none;}
.downloads-mobile-title{display: none;}
.downloads-desktop-title{display: block;}
.downloads-text{font-weight: 900; color: $white; font-size: 2vw; margin-bottom: 1%; margin-left: 10%;}
.downloads-triangle-booklet{width: 65%; transform: rotate(292deg); transition: 0.3s ease;}
.downloads-triangle-whitepaper{width: 65%; transform: rotate(311deg); transition: 0.3s ease;}
.download-row{display: flex; justify-content: center; margin: 4% 6% 10% 2%; position: relative;}
.download-booklet{
  width: 40%; display: flex; justify-content: center; flex-direction: column; align-items: flex-end; cursor: pointer; position: relative; margin-right: 2%;
  .booklet-cover-img{width: 55%; top: 32%; right: 0; transform-style: preserve-3d; transition: 0.3s ease; filter: drop-shadow(-1vw 1vw 0.75vw rgba(0, 0, 0, 0.63))}
  .download-icon{
    top: 81%; right: 37%; border-radius: 8rem; background-color: $lavender-bg; width: 15%; transition: 0.2s ease;
    img{width: 100%;}
  }
  &:hover{
    .downloads-triangle-booklet{transform: rotate(320deg); filter: drop-shadow(0vw 0vw 1vw #fff);}
    .booklet-cover-img{transform: scale(1.1) rotateY(20deg) rotateX(30deg) rotateZ(-10deg);}
    .download-icon{transform: scale(1.2);}
  }
}
.download-whitepaper{
  width: 40%; display: flex; justify-content: center; flex-direction: column; align-items: flex-start; cursor: pointer; position: relative; margin-left: 2%;
  .whitepaper-cover-img{width: 34%; top: 32%; right: 46%; transform-style: preserve-3d; transition: 0.3s ease; filter: drop-shadow(-1vw 1vw 0.75vw rgba(0, 0, 0, 0.63))}
  .download-icon{
    top: 81%; left: 46%; border-radius: 8rem; background-color: $lavender-bg; width: 15%; transition: 0.2s ease;
    img{width: 100%;}
  }
  &:hover{
    .downloads-triangle-whitepaper{transform: rotate(340deg); filter: drop-shadow(0vw 0vw 1vw #fff);}
    .whitepaper-cover-img{transform: scale(1.1) rotateY(20deg) rotateX(30deg) rotateZ(-10deg);}
    .download-icon{transform: scale(1.2);}
  }
}

//chapters
.chapters-title-mobile{display: none;}
.chapters-title-desktop{display: block;}
.chapters-title{color: $white; font-size: 4.5vw; font-family: $roboto; text-transform: uppercase; text-align: center; position: relative; font-weight: 900; margin: 0 auto; line-height: 90%; margin-top: 3%; padding: 0 18%; width: 85%; filter: drop-shadow(0.2vw 0.2vw 0.3vw #0000007d);}
.chapter-strap{color: $white; font-size: 1.2vw; font-family: $roboto; text-align: center; font-weight: 200; margin: 1.5% auto 3% auto;}
#chapter_one, #chapter_six, #chapter_eleven{background-color: $pink-bg; color: $pink-txt;}
#chapter_two, #chapter_seven, #chapter_twelve{background-color: $lavender-bg; color: $lavender-txt;}
#chapter_three, #chapter_eight{background-color: $green-bg; color: $green-txt;}
#chapter_four, #chapter_nine{background-color: $purple-bg; color: $purple-txt;}
#chapter_about, #chapter_five, #chapter_ten{background-color: $yellow-bg; color: $yellow-txt;}
#chapter_one_one, #chapter_one_two, #chapter_one_three{background-color: $pink-bg-light; color: $pink-txt;}
#chapter_two_one, #chapter_two_two, #chapter_two_three, #chapter_two_four, #chapter_two_five, #chapter_two_six, #chapter_two_seven, #chapter_two_eight{background-color: $lavender-bg-light; color: $lavender-txt;}
#chapter_three_one, #chapter_three_two, #chapter_three_three{background-color: $green-bg-light; color: $green-txt;}
#chapter_four_one, #chapter_four_two, #chapter_four_three{background-color: $purple-bg-light; color: $purple-txt;}
#chapter_five_one, #chapter_five_two, #chapter_five_three, #chapter_five_four{background-color: $yellow-bg-light; color: $yellow-txt;}
#chapter_six_one, #chapter_six_two, #chapter_six_three{background-color: $pink-bg-light; color: $pink-txt;}
#chapter_seven_one, #chapter_seven_two, #chapter_seven_three{background-color: $lavender-bg-light; color: $lavender-txt;}
#chapter_eight_one, #chapter_eight_two, #chapter_eight_three{background-color: $green-bg-light; color: $green-txt;}
#chapter_nine_one, #chapter_nine_two, #chapter_nine_three, #chapter_nine_four{background-color: $purple-bg-light; color: $purple-txt;}
#chapter_ten_one, #chapter_ten_two, #chapter_ten_three, #chapter_ten_four, #chapter_ten_five{background-color: $yellow-bg-light; color: $yellow-txt;}
#chapter_eleven_one, #chapter_eleven_two, #chapter_eleven_three{background-color: $pink-bg-light; color: $pink-txt;}
.chapter-content-area{
  padding: 2% 15%; font-size: 1.3vw; background-color: $white;
  li{line-height: 130%; padding-bottom: 0.5%; margin-left: 5%;}
}
.chapter-content-area li>ul>li:last-child{padding-bottom: 0%;}
.chapter-content-area li:last-child{padding-bottom: 3%;}
.chapter-brief{
  font-size: 1.3vw; padding: 2% 15%;
  li{line-height: 130%; padding-bottom: 0.5%; margin-left: 5%;}
  h2{margin-bottom: 3%;}
}
.chapter-brief li>ul>li:last-child{padding-bottom: 0%;}
.chapter-brief li:last-child{padding-bottom: 3%;}
.chapter-two-brief, .chapter-seven-brief{background-color: $lavender-bg-light;}
.chapter-three-brief, .chapter-eight-brief{background-color: $green-bg-light;}
.chapter-nine-brief, .chapter-four-brief{background-color: $purple-bg-light;}
.chapter-about-brief, .chapter-ten-brief, .chapter-five-brief{background-color: $yellow-bg-light;}
.chapter-six-brief, .chapter-eleven-brief, .chapter-one-brief{background-color: $pink-bg-light;}
.highlighted-text-container{margin-bottom: 2%;}
.highlighted-text{
  display:flex; padding: 1% 4%; margin: 0% 0 1% 0; border-radius: 0.5rem;
  svg{width: 5%; margin: 0% 3%;}
}
.warning-txt{
  display: flex; align-items: flex-start;
  svg{width: 8rem;}
}
.highlighted-text-lavender{background-color: $lavender-bg;}
.chapter-img{width: 100%; margin-bottom: 5%; margin-top: 2%;}
.file-icon{svg{width: 10%;}}
.important-txt{background-color: red; color: $white; padding: 0.5% 4%;; width: fit-content; margin-top: 1%; margin-bottom: 2%; border-radius: 4rem; font-weight: 500; font-size: 1.5vw;}
.chapters-example{border-radius: 4rem; background-color: #c3c3c3; color: #fff; padding: 0.5% 4%; width: fit-content; margin-bottom: 2%; margin-top: 1%; font-weight: 500; font-size: 1.5vw;}
.plus-icon{font-size: 3vw; font-weight: 200; position: absolute; right: 2%; transition: 0.3s ease;}
.plus-selected>span{transform: rotate(45deg);}
.nav-wrap {width: 100%;}
[hidden]{display: none; visibility: hidden;}
.acnav {
  width: 100%;
  &__list {padding: 0; margin: 0; list-style: none;}
  &__link, &__label {
    display: flex; align-items: center; position: sticky; top: 0; font-size: 2.4vw; font-weight: 900; padding: 1.25% 4.3% 1.25% 2.25%; margin: 0; cursor: pointer; transition: .3s ease-in-out; z-index: 1;
  }
  &__link--level2, &__label--level2 {
    padding-left: 4.25%; font-size: 1.8vw; transition: .3s ease-in-out; border-top: 2px solid #fff;
  }
  &__link--level3, &__label--level3 {
    padding-left: 6.25%; font-size: 1.2vw;
  }
  &__list--level2, &__list--level3{
    display: none;
    .is-open > & {
      display: block;
    }
  }
}

//footer
.footer-container{
  padding: 1% 10%;
  color: $white;
  .footer-logos{display: flex; align-items: center; justify-content: space-around;}
  .en-logo{ width: 20%;
    img{width: 100%;}
  }
  .fnf-logo{ width: 22%;
    img{width: 90%;}
  }
  .copyright{width: 100%; text-align: center; font-size: 0.9vw;}
}

//media queries
//hover effects
@media (hover: hover) and (pointer: fine) {
  .acnav__link, .acnav__label{
    &:hover {padding-left: 4.25%;}
  }
  .downloads-mobile{
    &:hover{transform: scale(1.1) rotate(7deg);}
  }
  .downloads-desktop{
    &:hover{transform: scale(1.1) rotate(-5deg);}
  }
  .play-btn{
    transition: 0.3s ease;
    &:hover{
      box-shadow: inset 0px 0px 20px 0px #ffc96b;
    }
  }
  .panel-members{
    &:hover{
      // filter: drop-shadow(0rem 0rem 0.5rem $white);
      cursor: pointer;
      .panel-two-img-one{transform: translate(0, 0%);}
      .panel-two-img-two{transform: translate(0, 10%);}
      .panel-two-img-three{transform: translate(0, 2%);}
      .panel-two-name-one{transform: translate(0, 15%);}
      .panel-two-name-two{transform: translate(0, 35%);}
      .panel-two-name-three{transform: translate(0, 25%);}
      .panel-one-img-one{transform: translate(0, -3%);}
      .panel-one-img-two{transform: translate(0, 20%);}
      .panel-one-img-three{transform: translate(0, -6%);}
      .panel-one-name-one{transform: translate(0, 25%);}
      .panel-one-name-two{transform: translate(0, 40%);}
      .panel-one-name-three{transform: translate(0, 20%);}
      .hover-splash-panel-one{transform: scale(1.1) rotate(-20deg) translate(-5%, 6%);}
      .hover-splash-panel-two{transform: scale(1) rotate(7deg) translate(7%, 3%);}
      .panel-one-bg{transform: rotate(-20deg);}
      .panel-two-bg{transform: rotate(-20deg);}
    }
  }
  .highlight{
    &:hover{
      filter: drop-shadow(0rem 0rem 0.5rem $white);
      .highlight-guest-img{transform: scale(1.15) translate(0%, -5%);}
      .highlight-swirl-img{transform: scale(1.4) translate(0%, -3%) rotate(20deg);}
      .highlight-member-name{padding-top: 8%;}
      svg{top: 37%; width: 45%; right: 28%;}
    }
  }
}

//mobile
@media(max-width: $phone) {
  p{padding: 0% 0% 8% 0%; line-height: 130%;}
  p>svg{width: 15%; margin-right: 5%;}
  li>ul{margin-top: 8%;}
  .row-sm{width: 100%; display: flex; flex-wrap: wrap; margin: 0 auto;}
  .hex-container{display: none;}
  .separation-line{display: none;}

  //banner
  .banner{display: none;}
  .banner-mobile{
    display: block; position: relative; height: 80vh;
    .banner-swirls-mobile{width: 100%; top: -25vh; opacity: 0.3;}
    .banner-figure{width: 120%; top: 10vh; left: -20vw;}
    .banner-thumbprint{
      width: 28%; top: 16vh; right: 3vw;
      img{width: 100%;}
      .line{width: 46%; height: 58%; top: 18%; right: 14.5%; border-radius: 50%; overflow: hidden;}
    }
    .banner-icon-document{width: 10%; top: 10vh; left: -3vw;}
    .banner-icon-padlock{width: 10%; top: 8vh; right: 30vw;}
    .banner-icon-person{width: 10%; bottom: 26vh; right: 4vw;}
    .title-text{font-size: 12vw; color: #fff; font-weight: 900; bottom: 5%; left: 5vw; text-transform: uppercase; width: 90%; font-weight: 900; line-height: 85%;}
    .title-act{font-size: 5.5vw; font-weight: 300; line-height: 100%; text-align: center; margin-top: 3%; margin-bottom: 5%;}
    .top-logos{
      padding: 3.5% 5%;
      .en-logo img{width: 30%;}
      .fnf-logo img{width: 30%;}
    }
  }
  
  //info section
  .questions-container{display: block;}
  .question-title{color: $white; font-size: 10.5vw; font-family: $roboto; text-transform: uppercase; line-height: 100%; font-weight: 900;}
  .simple-characters{font-size: 7vw; font-weight: 400; font-family: $roboto; line-height: 4vw;}
  .answer{color: $white; font-size: 1vw; line-height: 1.3vw;}
  .first-question{padding: 3% 20% 5% 0%; margin-left: -5%; animation: sidebounce 4s infinite; img{box-shadow: 0px 0px 10px 2px #fff;; border-radius: 1rem;}}
  .second-question{padding: 3% 0% 5% 20%; margin-right: -5%; animation: sidebouncetwo 4s infinite; img{box-shadow: 0px 0px 10px 2px #fff;; border-radius: 1rem;}}
  .third-question{padding: 3% 20% 5% 0%; margin-left: -5%; animation: sidebounce 4s infinite; img{box-shadow: 0px 0px 10px 2px #fff;; border-radius: 1rem;}}
  .panel-one-answer{font-size: 6vw; background: linear-gradient(45deg, #5a87f8, #6d96fb); padding: 8%; color: $white; border-radius: 1rem; border: 1px solid $white;}
  .panel-two-answer{font-size: 6vw; background: linear-gradient(45deg, #f7ad36, #f1b658); padding: 8%; color: $white; border-radius: 1rem; border: 1px solid $white;}
  .panel-three-answer{font-size: 6vw; background: linear-gradient(45deg, #f538c9, #f552ce); padding: 8%; color: $white; border-radius: 1rem; border: 1px solid $white;}
  @keyframes sidebounce {
    0%{transform: translate(0%, 0%);}
    15%{transform: translate(3%, 0%);}
    30%{transform: translate(0%, 0%);}
    40%{transform: translate(1.5%, 0%);}
    50%{transform: translate(0%, 0%);}
    100%{transform: translate(0%, 0%);}
  }

  @keyframes sidebouncetwo {
    0%{transform: translate(0%, 0%);}
    15%{transform: translate(-3%, 0%);}
    30%{transform: translate(0%, 0%);}
    40%{transform: translate(-1.5%, 0%);}
    50%{transform: translate(0%, 0%);}
    100%{transform: translate(0%, 0%);}
  }

  //panels
  .discussions-separation{border-top: 1px solid #fff; width: 85%; margin: 8% auto 10% auto;}
  .discussions-title-desktop{display: none;}
  .discussions-title-mobile{display: block;}
  .discussions-title{width: 65%; margin-bottom: 2%;}
  .discussions-strap{font-size: 4.5vw; font-weight: 300; text-align: center; width: 85%;}
  .panel-members{
    width: 100%;
    .panel-one-name-one{
      font-size: 2.75vw;
      p{font-size: 2vw;}
    }
    .panel-one-name-two{
      font-size: 2.75vw;
      p{font-size: 2vw;}
    }
    .panel-one-name-three{
      font-size: 2.75vw;
      p{font-size: 2vw;}
    }
    .panel-two-name-one{
      font-size: 2.75vw;
      p{font-size: 2vw;}
    }
    .panel-two-name-two{
      font-size: 2.75vw;
      p{font-size: 2vw;}
    }
    .panel-two-name-three{
      font-size: 2.75vw;
      p{font-size: 2vw;}
    }
  }
  .panel-one{
    flex-direction: column; padding: 0% 5%; margin-top: 10%; margin-left: 0vw;
    .panel-members{margin-bottom: 2%;}
  }
  .panel-two{
    flex-direction: column; margin-top: 45%; padding: 0% 5%; margin-right: 0vw;
    .panel-members{margin-bottom: 2%;}
  }
  .panel-title{font-size: 6.5vw; margin: 6% 0% 3% 0%; width: 100%;}
  .panel-desc{
    line-height: 125%;
    p{font-size: 4.8vw; padding: 0% 0% 5% 0%;}
    span{font-size: 4vw;}
  }
  .play-btn{
    padding: 6% 0%; width: 90%; font-size: 5vw; margin-top: 9%; letter-spacing: 3px;
    svg{display: none;}
  }
  .discussions-computer, .discussions-note, .discussions-squares, .hacker, .hacker-tbl{display: none;}

  //highlights
  .highlights-mobile{display: block;}
  .highlights-desktop{display: none;}
  .highlights-container{margin-top: 40%;}
  .highlights-title-section{padding: 0 0;}
  .highlights-title{width: 50%;}
  .highlights-strap{font-size: 4.5vw; margin: 2% auto 8% auto; font-weight: 300; text-align: center; width: 85%;}
  .highlight{
    margin: 0% 0% 10% 0%; width: 50vw;
    svg{top: 19vh; right: 35%; width: 14vw;}
  }
  .highlight-img-container{width: 38vw; height: 22vh;}
  .highlight-member-name{font-size: 4vw;}
  .highlight-title{font-size: 4vw;}
  .highlights-row{padding: 0 3%;}

  //popup
  .popup-content{width: 90% !important;}
  .close{font-size: 7vw; width: 10%;}

  //downloads
  .downloads-section{margin-top: 10%;}
  .downloads-title{margin-top: 10%; width: 80%; margin-bottom: 3%;}
  .downloads-strap{font-size: 4.5vw; padding: 0; font-weight: 400; width: 85%; margin-bottom: 3%;}
  .downloads-mobile-title{display: block;}
  .downloads-desktop-title{display: none;}
  .download-row{flex-direction: column; align-items: center; margin: 4% 2% 25% 2%;}
  .download-booklet{
    align-items: center; width: 80%; margin-right: 0; margin-top: 5%;
    .booklet-cover-img{top: 37%; right: 21%;}
    .download-icon{top: 78%; right: 15%; width: 20%;}
  }
  .download-whitepaper{
    .whitepaper-cover-img{width: 36%; top: 41%; right: 33%;}
    .download-icon{top: 86%; left: 56%; width: 20%;}
  }
  .download-whitepaper{align-items: center; width: 80%; margin-left: 0; margin-top: 18%;}
  .downloads-text{margin-left: 0; font-size: 5vw; margin-bottom: 2%;}
  .downloads-booklet-description{font-size: 2.75vw; text-align: center;}
  .downloads-whitepaper-description{font-size: 2.75vw; text-align: center; margin-left: 0%;}
  .downloads-triangle-booklet{margin-left: -10%;}
  .downloads-triangle-whitepaper{margin-left: -10%;}

  //chapters
  .chapters-title-mobile{display: block;}
  .chapters-title-desktop{display: none;}
  .chapter-strap{font-size: 4.5vw; font-weight: 300; text-align: center;}
  .acnav__link, .acnav__label{padding: 1rem 3rem 1rem 1.5rem; font-size: 1.5rem;}
  .plus-icon{right: 5%; font-size: 8vw;}
  .chapter-content-area{
    padding: 5% 12%; font-size: 3.8vw;
    li{padding-bottom: 2%;}
  }
  .chapter-content-area li>ul>li:last-child{padding-bottom: 2%;}
  .chapter-content-area li:last-child{padding-bottom: 8%;}
  .chapter-brief{
    padding: 5% 12%; font-size: 3.8vw;
    h2{margin-bottom: 8%;}
    li{padding-bottom: 2%;}
  }
  .chapter-brief li>ul>li:last-child{padding-bottom: 2%;}
  .chapter-brief li:last-child{padding-bottom: 3%;}
  .highlighted-text{
    padding: 4%;
    svg{width: 30%; margin: 0% 8% 0% 3%;}
  }
  .file-icon{svg{width: 60%;}}
  .important-txt{padding: 0.5% 4%; margin-top: 8%; margin-bottom: 5%; font-size: 4.5vw;}
  .chapters-example{font-size: 4.5vw; margin-bottom: 3%; margin-top: 2%; padding: 0.5% 6%;}
  .chapters-title{padding: 0; width: 80%; margin-bottom: 3%;}
  .chapter-strap{font-size: 4.5vw; margin: 1.5% auto 5% auto; padding: 0% 5%;}
  .highlighted-text-container{margin-bottom: 8%;}
  .warning-txt{
    svg{width: 5rem;}
  }

  //footer
  .footer-container{
    padding: 5% 18%; font-size: 2.5vw;
    .footer-logos{display: flex; flex-direction: column;}
    .en-logo{width: 65%; margin-top: 5%;}
    .fnf-logo{width: 75%; margin-top: 5%;}
    .copyright{font-size: 2.5vw; margin-top: 5%;}
  }
}

@media(max-width: $phone) and (max-height: 700px) {
  .banner-mobile{
    height: 80vh;
    .banner-figure{width: 100%; top: 10vh; left: -15vw;}
    .banner-icon-person{bottom: 36vh;}
  }
  .downloads-strap{margin-bottom: 2%;}
  .downloads-bg img{height: 100vh;}
  .downloads-container{
    height: 80vh;
    .downloads-text{width: 80%; top: unset; font-size: 6vw;}
    .downloads-mobile{width: 14%; top: 5%; left: 43%;}
    .downloads-desktop{width: 42%; right: 29%; top: 49%;}
    .downloads-mobile-txt{text-align: center; top: 27%; left: 10%;}
    .downloads-desktop-txt{text-align: center; top: 71%; right: unset; left: 10%;}
    .downloads-mobile-description{width: 75%; left: 12%; top: 33%; font-size: 4vw; text-align: center;}
    .downloads-desktop-description{width: 75%; left: 12%; top: 77%; font-size: 4vw; text-align: center;}
  }
}